export { appGrid };


import { debounce } from '../modules/helpers.js';


/*****************************************************************************/
/* 
/* GRID
/*
/*****************************************************************************/

var appGrid = new Vue({
    data: {
        lazyLoadInstance: null,
        scrollTopVisible: false,

        gridName: AppSettings.gridName,
        referer: AppSettings.referer,
    },
    beforeMount: function() {
        window.addEventListener('resize', debounce(this.adjustScrollTopLink, 250));
    },
    mounted: function() {
        this.adjustScrollTopLink();
        this.restoreScrollPos();
        
        this.lazyLoadInstance = new LazyLoad({
            elements_selector: '.lazy-load'
        });
    },
    methods: {
        scrollToTop: function() {
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        },
        restoreScrollPos: function() {
            var now = new Date();
            var expirationTime = localStorage.getItem('expirationTime');
            if (null === expirationTime || now.getTime() > expirationTime) {
                localStorage.clear();
                return;
            }

            // matches e.g. /objects/buche-easy-chair/ or /archive/gispen-chairs/
            var regex = /\/([a-z0-9\-]+)\/([a-z0-9\-]+)\//
            var match = this.referer.match(regex);
            if (match && match[1] == this.gridName) {
                var objectName = match[2];  
                var scrollTop = localStorage.getItem(this.getObjectKey(objectName));
                if (null !== scrollTop) {
                    window.scrollTo({top: scrollTop});      
                }
            }
        },
        adjustScrollTopLink: function() {
            this.scrollTopVisible = (document.body.clientHeight > window.innerHeight + 300);
        },
        handleClick: function(objectName) {
            var scrollTop = window.pageYOffset || document.documentElement.scrollTop;
            localStorage.setItem(this.getObjectKey(objectName), scrollTop);

            var now = new Date();
            var ttl = 3600000; // 1h = 3600s = 3600000ms
            localStorage.setItem('expirationTime', now.getTime() + ttl);
        },
        getObjectKey: function(objectName) {
            return this.gridName +'--'+ objectName;
        },
    },
    computed: {
        classScrollTopVisible: function() {
            return this.scrollTopVisible ? 'visible' : '';
        }
    },
});

if (document.getElementById('appGrid')) {
    appGrid.$mount('#appGrid');
}

export { appHeader };


/*****************************************************************************/
/* 
/* GRID
/*
/*****************************************************************************/

var appHeader = new Vue({
    data: {
        isVisibleOnMobile: false,    
    },
    mounted: function() {
        
    },
    methods: {
        triggerMobileMenu: function() {
            this.isVisibleOnMobile = !this.isVisibleOnMobile; 
        }
    },
    computed: {
        classVisibleOnMobile: function() {
            return this.isVisibleOnMobile ? 'visible-on-mobile' : '';
        },
        classShowSymbol: function() {
            return this.isVisibleOnMobile ? 'show-close-x' : 'show-hamburger';
        }
    }

});

if (document.getElementById('appHeader')) {
    appHeader.$mount('#appHeader');
}

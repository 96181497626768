export { appSlides };


import { ImagePreloader } from '../modules/image-preloader';
import { Velocity } from '../modules/velocity';

import { throttle } from '../modules/helpers'


/*****************************************************************************/
/* 
/* SLIDES
/*
/*****************************************************************************/

var appSlides = new Vue({
    data: {
        images: AppSettings.images,

        currentImageIdx: 0,
        
        slides: [],

        currentSlideIdx: 0,
        leftSlideIdx: 1,
        rightSlideIdx: 2,

        hiddenSlideIdx: -1,

        loader: null,
        velocity: null,

        isVisible: false,

        cursorStart: 0,
        cursorCurrent: 0,

        draggingSpeed: 1.2,
        dragVelocityThreshold: 700,
        dragging: false,

    },
    beforeMount: function() {
        this.loader = new ImagePreloader();
        this.velocity = new Velocity();

        window.addEventListener('resize', throttle(this.adjustViewportHeight, 50));
    },
    mounted: function() {
        this.initSlides();
        this.updateSlides();
        this.preloadNextImages(3);
    },
    methods: {
        initSlides: function() {
            for (var idx = 0; idx < 3; idx++) {
                    this.slides.push({
                    src: '',
                    srcset: '',
                    sizes: '',
                    classPosition: '',
                    classHidden: '',
                    classLoading: '',
                });  
            }
        },

        prevImage: function() {
            this.currentImageIdx = this.imageIdx(-1);

            // Slides

            this.hiddenSlideIdx = this.rightSlideIdx;

            this.rightSlideIdx = this.currentSlideIdx;
            this.currentSlideIdx = this.leftSlideIdx;
            this.leftSlideIdx = this.hiddenSlideIdx;

            this.updateSlides();
            this.preloadPrevImages(3);
        },
        nextImage: function() {
            this.currentImageIdx = this.imageIdx(+1);

            // Slides

            this.hiddenSlideIdx = this.leftSlideIdx;

            this.leftSlideIdx = this.currentSlideIdx;
            this.currentSlideIdx = this.rightSlideIdx;
            this.rightSlideIdx = this.hiddenSlideIdx;

            this.updateSlides();
            this.preloadNextImages(3);
        },
        imageIdx: function(offset) {
            var offset = offset || 0;
            return (this.currentImageIdx + offset + this.images.length) % this.images.length;
        },


        preloadPrevImages: function(offset) {
            this.preloadImages(offset, true);
        },
        preloadNextImages: function(offset) {
            this.preloadImages(offset);
        },
        preloadImages: function(imageCount, reverse) {
            var reverse = (undefined === reverse) ? false : reverse;
            var sign = reverse ? -1 : 1;
            
            var preloadImages = [];
            for (var offset = 0; offset < this.images.length; offset++) {
                var imageIdx = this.imageIdx(sign * offset);
                var image = this.images[imageIdx];
                if (!image.hasOwnProperty('loaded') || !image.loaded) {
                    preloadImages.push(image);
                }
                if (preloadImages.length == imageCount) {
                    break;
                }
            }
            if (preloadImages.length) {
                this.loader.preloadSet(preloadImages, function() {
                    preloadImages.map(function(img) { img.loaded = true });
                })
            }
        },

        updateSlides: function() {
            this.updateSlide(this.currentSlideIdx, this.imageIdx());
            this.updateSlide(this.rightSlideIdx, this.imageIdx(+1));
            this.updateSlide(this.leftSlideIdx, this.imageIdx(-1));

        },
        updateSlide: function(slideIdx, imageIdx) {
            var slide = this.slides[slideIdx];

            switch (slideIdx) {
                case this.leftSlideIdx:
                    slide.classPosition = 'left-off';
                    break;
                case this.rightSlideIdx:
                    slide.classPosition = 'right-off';
                    break;
                default:
                    slide.classPosition = '';
            }

            slide.classHidden = (slideIdx === this.hiddenSlideIdx) ? 'hidden' : '';
            // time needed for the hidden slide of the three to make its way to the other side 
            window.setTimeout(function() {slide.classHidden = '';}, 100);

            
            var image = this.images[imageIdx];
            if (image.hasOwnProperty('loaded') && image.loaded) {
                slide.sizes = image.sizes;
                slide.srcset = image.srcset;
                slide.src = image.src;
            } else {
                slide.classLoading = 'loading';
                var self = this;
                this.loader.preload(image, function() {
                    slide.sizes = image.sizes;
                    slide.srcset = image.srcset;
                    slide.src = image.src;

                    image.loaded = true;

                    slide.classLoading = '';

                    // show whole slideshow
                    self.isVisible = true;
                });
            }
        },

        adjustViewportHeight: function() {
            document.documentElement.style.setProperty('--viewport-height', window.innerHeight + "px");
        },


        /* Image Swipe Code starts here */

        // inspired by: https://www.labnol.org/code/19616-detect-touch-screen-javascript
        isTouchDevice: function() {
            return (('ontouchstart' in window)
                 || (navigator.MaxTouchPoints > 0)
                 || (navigator.msMaxTouchPoints > 0));
        },

        getCursor: function(event) {
            if (event.touches && event.touches.length) {
                // touch
                return event.touches[0].pageX;
            }

            if (event.pageX && event.pageY) {
                // mouse
                return event.pageX;
            }

            return 0;
        },

        startDrag: function(event) {
            this.dragging = true;

            this.cursorStart = this.getCursor(event);
            this.cursorCurrent = this.cursorStart;

            this.velocity.reset();
        },
        drag: function(event) {
            if (!this.dragging) return;

            this.cursorCurrent = this.getCursor(event);

            this.velocity.updatePosition(this.cursorCurrent);
        },
        stopDrag: function(event) {
            if (!this.dragging) return;

            this.dragging = false;

            var dragVelocity = Math.abs(this.velocity.getVelocity());
            var fastEnough = dragVelocity > this.dragVelocityThreshold;

            var diffX = this.cursorCurrent - this.cursorStart;
            var farEnough = Math.abs(diffX) > window.innerWidth / 5;
            
            if (farEnough || fastEnough) {
                if (diffX < 0) this.nextImage();
                if (diffX > 0) this.prevImage();
            }
        },

        styleDragPosition: function(slideIdx) {
            if (!this.dragging) return '';

            var draggingDistance = this.cursorCurrent - this.cursorStart;
            draggingDistance *= this.draggingSpeed;
            switch (slideIdx) {
                case this.currentSlideIdx:
                    return 'transform: translate('+ draggingDistance +'px, 0)';
                case this.leftSlideIdx:
                    return 'transform: translate(calc(-100vw + '+ draggingDistance +'px), 0)';
                case this.rightSlideIdx:
                    return 'transform: translate(calc(100vw + '+ draggingDistance +'px), 0)';
                default:
                    return '';
            }
        },

    },
    computed: {
        src: function() {
            return this.images[this.currentImageIdx].src;
        },
        classIsTouchDevice: function() {
            return this.isTouchDevice() ? 'touch' : '';
        },
        classIsVisible: function() {
            return this.isVisible ? 'visible' : '';
        },
        classDragging: function() {
            return this.dragging ? 'dragging' : '';
        },
    },
});

if (document.getElementById('appSlides')) {
    appSlides.$mount('#appSlides');
}

export { appObjectDetails };


/*****************************************************************************/
/* 
/* OBJECT DETAILS
/*
/*****************************************************************************/

var appObjectDetails = new Vue({
    methods: {
        scrollToDetails: function() {
            var scrollPos = this.$el.offsetTop;

            if (window.innerWidth > AppSettings.breakpoints.medium) {
                scrollPos -= 12;                
            } else {
                var gridTitle = document.getElementById('gridTitle');
                scrollPos -= gridTitle.offsetTop
            }

            window.scrollTo({
                top: scrollPos,
                behavior: 'smooth',
            });
        }
    }
});

if (document.getElementById('appObjectDetails')) {
    appObjectDetails.$mount('#appObjectDetails');
}
